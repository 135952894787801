// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Impressum',
  slug: '/impressum',
  abstract:
    'Since 1988 Prisma Books of Auroville publishes good old-fashioned, beautifully curated books. Prisma Books offers full publishing services in design, editorial, printing, artwork, illustrations, photography, digital media, exhibition and curatorship.',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Impressum', slug: '/impressum' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <h1>Impressum // Copyright notice</h1>
    <p className="dropcap">
      <span>A</span>ll text is the copyright of the Sri Aurobindo Ashram Trust,
      Pondicherry with the exception of the text from the Mother's Agenda. The
      copyright holder for Mother's Agenda is "Institut deRecherches
      Evolutives."
    </p>
    <ul>
      <li>Project created by Loretta Shartsis</li>
      <li>Talks presented by Loretta Shartsis</li>
      <li>Draings by Loretta Shartsis</li>
      <li>Designed & Published by Prisma</li>
      <li>
        Publisher's address: Aurelec / Prayogashala, Auroville 605101, Tamil
        Nadu, India
      </li>
      <li>Project creator's email: loretta@auroville.org.in</li>
      <li>Publisher's email: prisma@auroville.org.in</li>
      <li>Publisher's website: https://books.prisma.haus</li>
      <li>Book designed and published by Prisma Books</li>
      <li>Web Book handcrafted by Prisma Design</li>
    </ul>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
